@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.profile {
  @include tablet {
    zoom: 67%;
  }
  width: 90%;
  margin: 0 auto;
  @include mobile-only {
    width: 100%;
    // height: 100%;
    // margin: 0rem auto;
    // margin-top: 1.5rem;
    // border: 2px solid;
    border-radius: 25px;
    // overflow: hidden;
    box-shadow: $shadow-2;
    background-color: white;
    padding: 1.5rem 1rem 0.5rem;
    // transform: scale(0.8);
    &--alt {
      background-color: $bg-colour-dark;
    }
  }
  &__container {
    @include mobile-only {
      text-align: center;
    }
  }
  @include tablet {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 1rem;
  }

  &__logo {
    width: 100%;
    height: 100%;
  }

  &__carousel--outer {
    // margin-top: 2rem;
    margin: 0.75rem 0;
    overflow: hidden;
    height: 50px;
    @include tablet {
      width: 80%;
    }
  }
  &__text-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include mobile-only {
      font-size: $size-text-mobile;
    }
    font-size: 1.25rem;
    margin: 0.5rem 0 1.5rem 0;
  }
  &__text {
    margin: 0;
  }
  &__icons {
    position: relative;
    @include mobile-only {
      margin: 0.75rem auto;
    }
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // height: 20%;
    margin-bottom: 40%;
    // height: 100%;
    // width: 90%;
    // @include tablet {
    //   width: 60%;
    // }
    background-color: none;
    &::after {
      @include tablet {
        content: "";
        position: absolute;
        height: 150%;
        bottom: -150%;
        width: 2px;
        margin: 0 1.4rem;
        background: $bg-colour;
        z-index: -1;
      }
    }
  }
  &__links {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
      width: unset;
    }
  }
  &__follow {
    display: none;
    @include tablet {
      text-align: center;
      display: block;
      left: -0.5rem;
      position: absolute;
      bottom: -150%;
      margin: 0;
      transform-origin: 22.5%;
      background: white;

      padding: 0rem 0.5rem;
      font-size: 1.25rem;
      transform: rotate(-90deg);

      width: 9rem;
      // font-size: $size-text;
      text-transform: uppercase;
    }
  }
  &__icons--dark {
    .profile__follow {
      background-color: $bg-colour-dark;
    }
  }
  &__icon {
    width: 3rem;
    height: 3rem;
    @include tablet {
      margin-right: 1rem;
    }
    border-radius: 50%;
    padding: 0.5rem;
    transition: background-color 0.5s ease;

    &:hover {
      background-color: #efefef;
      @include pulse(0, 0, 0);
    }
  }
  &__buttons {
    display: flex;
    gap: 1rem;
    @include mobile-only {
      width: fit-content;
      margin: auto;
    }
    &--alt {
      .profile__button {
        filter: invert(1);
      }
    }
  }
  &__button {
    padding: 0.5rem 1rem;
    font-weight: 600;
    text-decoration: none;
    border: 2px solid $bg-colour;
    color: $bg-colour;
    border-radius: 25px;
    transition: all 0.5s;
    cursor: pointer;
    font-size: $size-text;
    &:hover {
      background-color: $bg-colour;
      color: white;
    }
    &--alt {
      background-color: $bg-colour;
      color: white;
      &:hover {
        background-color: white;
        color: $bg-colour;
      }
    }
  }
  &__icon--alt {
    filter: invert(100%);
    &:hover {
      background-color: rgba(128, 128, 128, 0.5);
      @include pulse(0, 0, 0);
    }
  }
  &__icon--user {
    border-radius: 25%;
  }
  &__flip-container {
    perspective: 1000;
    display: inline-block;
  }
  @include mobile-only {
    &__flip-container &__card {
      animation: repeat 4s ease-in-out infinite;

      @keyframes repeat {
        0%,
        100% {
          transform: rotateY(0);
        }

        50% {
          transform: rotateY(180deg);
        }
      }
    }
    &__flip-container:hover &__card {
      animation-play-state: paused;
    }
  }
  &__flip-container:hover &__card {
    transform: rotateY(180deg);
  }
  &__card,
  &__front,
  &__back {
    width: 17.5rem;
    height: 17.5rem;
    @include mobile-only {
      width: 15rem;
      height: 15rem;
    }
    border-radius: 50%;
    // margin-top: 1rem;
  }
  &__card {
    transform-style: preserve-3d;
    transition: 0.5s;
    position: relative;
  }
  &__front,
  &__back {
    backface-visibility: hidden;
    position: absolute;
    // border: 2px solid;
  }
  &__front {
    z-index: 2;
    transform: rotateY(0deg);
    background: url("../../assets/images/headshot.jpg") no-repeat;
    background-size: 17.5rem;
    @include mobile-only {
      background-size: 15rem;
    }
  }
  &__back {
    z-index: 1;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bg-colour;
    &--alt {
      filter: invert(1);
      // border: 2px solid black;
    }
  }

  &__carousel {
    -webkit-animation: scroll 16s ease-in-out infinite;
    animation: scroll 16s ease-in-out infinite;
  }

  @keyframes scroll {
    0%,
    5% {
      transform: translateY(50px);
    }
    20%,
    5% {
      transform: translateY(0);
    }
    25%,
    45% {
      transform: translateY(-50px);
    }
    50%,
    70% {
      transform: translateY(-100px);
    }
    75%,
    95% {
      transform: translateY(-150px);
    }
    100% {
      transform: translateY(-200px);
    }
  }
  &__inner {
    font-size: 40px;
    @include tablet {
      font-size: 50px;
    }
    line-height: 50px;

    font-weight: 200;
    letter-spacing: 1.75px;
    margin: 0;
  }
}
