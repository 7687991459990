*,
*::after,
*::before {
  box-sizing: border-box;
}
body {
  margin: 0;
}
html {
  font-size: 100%;
}
