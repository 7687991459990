@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
.contact {
  @include mobile-only {
    // margin-top: 1.5rem;
    transform: scale(0.8);
    padding: 0.75rem;
    // height: 100vh;
    margin: auto;
  }
  @include tablet {
    zoom: 67%;
    width: 90%;
    padding: 1rem;
    // height: 100%;
  }
  background-color: white;

  box-shadow: $shadow-2;
  border-radius: 25px;
  // border: 2px solid;

  &--dark {
    background-color: $bg-colour-dark;
    color: white;
  }
  &__title {
    letter-spacing: 1.5px;
    font-size: $size-sub-header;
    margin: 0.5rem;
    font-weight: 100;
    text-transform: uppercase;
  }
  &__title,
  &__subtitle {
    text-align: center;
  }
  &__subtitle {
    font-size: $size-text-mobile;
    @include tablet {
      font-size: $size-text;
    }
  }
  &__email {
    @include underline(-15%, black);
  }
  &--dark {
    .contact__email {
      @include underline(-15%, white);
    }
  }
  &__link {
    text-decoration: none;
    font-weight: 600;
    color: unset;
  }

  &__form {
    width: 90%;
    margin: auto;
    overflow-x: hidden;
  }
  &__flex {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    @include mobile-only {
      gap: 0.5rem;
    }
    gap: 2rem;
  }
  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__error-container {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    gap: 0.5rem;
    right: 0;
    position: absolute;
    top: 0;
  }
  &__error {
    margin: 0;
  }
  &__message {
    position: relative;
    font-family: "Inter", "Roboto", "Helvetica", "Arial", "sans-serif";
    ::placeholder {
      font-family: "Inter", "Roboto", "Helvetica", "Arial", "sans-serif";
    }
  }
  &__textarea {
    font-family: "Inter", "Roboto", "Helvetica", "Arial", "sans-serif";
  }
  &__button-container {
    display: flex;
    justify-content: center;
  }
}

.active .contact__container div {
  opacity: 0;
  animation: fadeIn 1s ease-in both;
}

.contact__container div:nth-child(2) {
  animation-delay: 1s;
}
.contact__container div:nth-child(3) {
  animation-delay: 1s;
}
.contact__container div:nth-child(4) {
  animation-delay: 1s;
}
.contact__container div:nth-child(5) {
  animation-delay: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
