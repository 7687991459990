@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.workcarousel {
  height: 100%;
  @include mobile-only {
    // height: 100vh;
    // transform: scale(0.9);
    // margin-top: -12.5%;
  }
}
.slick {
  // &-arrow,
  // &-dots button {
  //   // @include filterWhiteToColour;
  // }
  &-arrow {
    display: none !important;
  }

  &-slider {
    height: 100%;
    display: block !important;
  }

  &-dots {
    @include filterWhiteToColour;
    bottom: 1rem;
    @include mobile-only {
      bottom: 5%;
    }

    li {
      border-radius: 50%;
      padding: 0;
      margin: 0;
      height: fit-content;
    }
  }
  &-dots li {
    border: none !important;
    margin: 0 0.2rem;
  }
  &-dots button {
    width: 100%;
    margin: auto;
  }

  &-list,
  &-track,
  &-slide > div:first-child,
  &-slide > div:first-child > div,
  &-slide > div:first-child {
    height: 100% !important;
  }
}

.workcarousel--alt {
  .slick-dots {
    filter: brightness(0) saturate(100%) invert(97%) sepia(6%) saturate(813%)
      hue-rotate(3deg) brightness(82%) contrast(86%);
  }
}
