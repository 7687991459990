@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.about {
  @include tablet {
    zoom: 67%;
  }
  width: 90%;
  margin: auto;
  @include mobile-only {
    // margin-top: 1.5rem;
    width: 100%;
    // height: 100vh;
    transform: scale(0.8);
  }
  padding-bottom: 1rem;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: $shadow-2;
  background-color: white;
  // background-color: $bg-colour;

  &--alt {
    background-color: $bg-colour-dark-alt;
    & .about__label {
      filter: invert(1);
    }
  }
  &__title {
    text-align: center;
    letter-spacing: 1.5px;
    font-size: $size-sub-header;
    font-weight: 100;
    text-transform: uppercase;
    margin: 0.5rem 0 0.25rem 0;
  }

  &__marquee {
    height: 6rem;
    @include tablet {
      height: 7rem;
    }
    overflow: hidden;
    position: relative;
    @include tablet {
      margin-bottom: 0.5rem;
    }
  }
  &__skill {
    float: left;
    width: calc((100% / 24) - 3rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 1.5rem;
  }
  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -200%;
    }
  }

  &__marquee-inner {
    margin-top: 0.5rem;
    display: block;
    width: 400%;
    position: absolute;
    animation: marquee 20s linear infinite;
    animation-play-state: running;
    &:hover {
      animation-play-state: paused;
    }
    @include tablet {
      margin-top: 0.75rem;
      // animation-play-state: paused;
      // &:hover {
      //   animation-play-state: running;
      // }
    }
  }
  &__label {
    z-index: -5;
    font-weight: 600;
    color: white;

    justify-content: center;
    align-items: center;
    position: absolute;
    margin: auto;
    text-align: center;
    font-size: 0.9rem;
    background-color: $bg-colour-alt;
    width: 4.75rem;
    height: 4.75rem;
    display: none;
    @include tablet {
      width: 6.25rem;
      height: 6.25rem;
      font-size: 1.1rem;
    }
    border-radius: 50%;
  }
  &__icon {
    background-color: $bg-colour;
    background-position: 50%;
    background-size: 50%;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    width: 4.75rem;
    height: 4.75rem;
    @include tablet {
      width: 6.25rem;
      height: 6.25rem;
    }
    border-radius: 50%;
    &:hover {
      > .about__label {
        z-index: 10;
        display: flex;
      }
    }
    &--alt {
      filter: invert(1);
      &:hover {
        background-color: $bg-colour;
        > .about__label {
          background-color: #c5c2ab;
          color: black;
        }
      }
    }
  }

  &__javascript {
    background-image: url("../../assets/icons/javascript.svg");
  }
  &__css {
    background-image: url("../../assets/icons/css.svg");
  }
  &__html {
    background-image: url("../../assets/icons/html.svg");
  }
  &__typescript {
    background-image: url("../../assets/icons/typescript.svg");
  }
  &__react {
    background-image: url("../../assets/icons/react.svg");
  }
  &__postgresql {
    background-image: url("../../assets/icons/postgresql.png");
  }
  &__express {
    background-image: url("../../assets/icons/express.svg");
  }
  &__tailwindcss {
    background-image: url("../../assets/icons/tailwindcss.png");
  }
  &__scss {
    background-image: url("../../assets/icons/scss.png");
  }
  &__nodejs {
    background-image: url("../../assets/icons/nodejs.png");
  }
  &__mysql {
    background-image: url("../../assets/icons/mysql.png");
  }
  &__nextjs {
    background-image: url("../../assets/icons/nextjs.png");
  }

  &__info {
    padding: 0.75rem;
    text-align: center;
    @include tablet {
      padding: 1rem;
    }
  }

  &__bio {
    @include mobile-only {
      font-size: $size-text-mobile;
    }
    margin-top: 0;

    font-size: $size-text;
    font-weight: 500;
    -webkit-text-stroke: 0.25px;
    -webkit-text-stroke-color: black;

    line-height: 1.75rem;
    @include tablet {
      font-size: 1.15rem;
    }
  }
  &__link {
    text-decoration: none;
    color: unset;
  }
  &__span {
    font-weight: 800;
    @include underline(-15%, black);
  }

  &__info--alt .about__span {
    @include underline(-15%, white);
  }
  &__connect {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    border-radius: 25px;

    border: 2px solid $bg-colour;
    color: $bg-colour;
    font-weight: 600;
    transition: all 0.5s;
    &:hover {
      background-color: $bg-colour;
      color: white;
    }
  }
  &__info--alt .about__connect {
    // background-color: $bg-colour-dark;
    filter: invert(1);
  }
  // &__info--alt,
  &__icon--alt {
    background-color: $bg-colour-dark;
  }
}
