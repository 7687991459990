$palette-1: #f2e9e4;
$palette-2: #c9ada7;
$palette-3: #9a8c98;
$palette-3-alt: #4a4e697d;
$palette-4: #4a4e69;
$palette-5: #22223b;

$palette-alt-1: #fbfbf2;
$palette-alt-2: #e5e6e4;
$palette-alt-3: #cfd2cd;
$palette-alt-4: #a6a2a2;
$palette-alt-5: #847577;

$app-colour: #f0f0f0;
$button-colour: #c9ada7;
$hover-colour: #22223b;
$bg-colour: rgb(74, 78, 105);
$bg-colour-alt: rgb(153, 140, 151);
$bg-colour-dark: #252529;
$bg-colour-dark-alt: #252529;

$tablet-min-size: 768px;
$desk-min-size: 1440px;

$size-header: 2rem;
$size-text: 1.1rem;
$size-text-mobile: 1rem;
$size-sub-header: 1.75rem;
$size-header-tablet: 3.75rem;
$size-sub_header-tablet: 2.5rem;

$shadow: 0px 0px 2px 0px $palette-alt-5;
$shadow-2: 0px 0px 3px 0px$palette-alt-5;
