@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.work {
  @include mobile-only {
    // margin-top: 1.5rem;
    margin: auto;
    transform: scale(0.8);
  }
  @include tablet {
    zoom: 67%;
    height: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // margin: auto;
  // box-shadow: $shadow-2;
  // border-radius: 25px;
  position: relative;
  // overflow: hidden;
  &--alt {
    height: 50%;
    @include mobile-only {
      display: block;
      margin: 0;
    }
    @include tablet {
      width: 90%;
    }
  }
  &__project {
    display: flex;
    flex-direction: column;
    // width: 100%;
    box-shadow: $shadow-2;
    border-radius: 25px;
    width: 90%;
    text-align: center;
    // justify-content: center;
    // align-items: center;
    &--alt {
      .work__info {
        display: none;
      }
      @include tablet {
        height: 35rem;
      }
    }
    @include mobile-only {
      width: 100%;
      // transform: scale(0.8);
    }
  }
  &__cont {
    // border: 2px solid;
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
    // background-color: rgba(21, 132, 99, 0.2);
    background-color: white;

    border-bottom: none;
    width: 100%;
    overflow: hidden;
    position: relative;

    &--dark {
      background-color: $bg-colour-dark-alt;
    }
  }
  &__carousel {
    width: 100%;

    @include mobile-only {
      height: 16.5rem;
    }
    @include tablet {
      height: 25rem;
    }

    position: relative;
  }
  &__image {
    padding: 0.5rem;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
  &__modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    // border: 2px solid;
    border-radius: 25px;
    padding: 0.5rem;
    z-index: 1;
    box-shadow: $shadow-2;
    overflow: hidden;
  }
  &__modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: block;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__button--alt {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    z-index: 31;
  }

  &__link {
    text-decoration: none;
    color: unset;
  }
  &__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    width: 100%;
    height: 100%;
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(2px);

      .work__icon {
        background-color: #efefef83;
      }
    }
    &--dark {
      &:hover {
        background-color: rgba(37, 37, 41, 0.5);
      }
      .work__icon {
        filter: invert(0);
      }
    }
  }
  &__icon {
    filter: invert(1);
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    width: 4.5rem;
    height: auto;
    transition: all 0.25s ease;
    background-color: rgba(255, 255, 255, 0.5);

    &:hover {
      transform: scale(1.15);
      filter: none;
    }
  }
  &__icon--alt {
    width: 3rem;
    transition: background-color 0.25 ease;
  }
  &__logo-container {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__logo {
    // max-height: 3rem;
    // margin-top: 0rem;
    // width: auto;
    @include filterWhiteToColour;
    text-align: center;
    letter-spacing: 1.5px;
    font-size: $size-sub-header;
    @include tablet {
      font-size: $size-header;
    }
    font-weight: 100;
    text-transform: uppercase;
    margin: 0.5rem 0 0.25rem 0;
  }
  &__logo--dark {
    filter: brightness(0) saturate(100%) invert(97%) sepia(6%) saturate(813%)
      hue-rotate(3deg) brightness(82%) contrast(86%);
  }

  &__info {
    padding: 1rem;

    @include mobile-only {
      padding: 0.75rem;
    }
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    z-index: 1;

    position: relative;
    // background-color: $bg-colour;
    background-color: white;
    // -webkit-text-stroke: 0.25px;
    // -webkit-text-stroke-color: black;

    &--dark {
      -webkit-text-stroke-color: white;
      background-color: $bg-colour-dark-alt !important;
    }
  }
  &__div {
    width: 100%;
  }

  &__list {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    @include mobile-only {
      gap: 0.25rem;
    }
    &--alt {
      visibility: hidden;
    }
  }
  &__list-item {
    font-weight: 700;
    letter-spacing: 1.5px;
    padding: 0.5rem;
    border-radius: 25px;
    border: 2px dashed;
    // background-color: $bg-colour-alt;
    color: white;
    // -webkit-text-stroke-color: white;
    // -webkit-text-stroke: 1px;
    font-size: $size-text;
    @include mobile-only {
      font-size: 0.8rem;
    }
    border: 2px solid$bg-colour;
    color: $bg-colour;
    // background-color: $bg-colour;
    &--alt {
      filter: invert(1);
    }
    // &--ashley {
    //   background-color: rgba(255, 0, 141, 0.5);
    //   border: 2px dashed rgb(255, 0, 141);
    // }
    // &--blink {
    //   // background-color: rgba(21, 132, 99, 0.5);
    //   // background-color: white;
    //   // border: 2px dashed rgba(21, 132, 99);
    //   border: 2px dashed $bg-colour;
    //   color: $bg-colour;
    // }
    // &--ashley {
    //   background-color: rgb(252, 219, 237);
    // }
  }
  &__list,
  &__bio {
    position: relative;
    z-index: 2;
    padding-left: 0.5rem;
  }
  &__bio {
    @include mobile-only {
      font-size: $size-text-mobile;
    }
    font-size: $size-text;
    font-weight: 500;
    // color: white;

    @include tablet {
      font-size: 1.15rem;
      margin-bottom: 0.25rem;
    }
  }
}

.loader {
  display: block;
  color: #ffffff;
  font-size: 45px;
  text-indent: -9999em;
  overflow: hidden;
  z-index: 2;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
div.css-1lhbuwb,
div.css-utvy5,
div.css-4s4m7w {
  display: none !important;
  visibility: hidden;
}
