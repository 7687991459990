@use "../styles/partials/mixins" as *;

.app {
  height: 100vh;
  width: 100vw;

  &__container,
  &__section--profile {
    @include mobile-only {
      //   display: none;
    }
  }
}
