@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/resets" as *;
@use "../../styles/partials/variables" as *;

.field {
  margin: 1rem 0;
  position: relative;

  &__label {
    display: none;
  }
  &__input {
    width: 100%;
    background-color: white;
    color: black;
    border: none;
    border-bottom: 2px solid $bg-colour;
    padding: 0.5rem 0;
    font-size: 1.25rem;
    position: relative;

    @include mobile-only {
      font-size: 1.1rem;
    }
    &::placeholder {
      font-size: 1.25rem;
      color: black;
      @include mobile-only {
        font-size: 1.1rem;
      }
    }
    &--dark {
      background-color: $bg-colour-dark-alt;
      border-bottom: 2px solid #c5c2ab;
      &::placeholder {
        color: white;
      }
    }
    &:active,
    &:focus {
      outline: none;
    }
    &--textarea {
      min-height: 0rem;
      height: fit-content;

      color: black;
      resize: none;
      position: relative;
      &:active {
        outline: none;
      }
    }
    &--error {
      border-bottom: 2px solid red;
      &::placeholder {
        color: red;
      }
    }
  }
  input[type="text"],
  input[type="email"],
  textarea {
    appearance: none;
    border-radius: 0;
    -webkit-appearance: none;
  }
}
