@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.ripple-button {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: white;
  color: $bg-colour;
  border: 2px solid $bg-colour;
  border-radius: 30px;
  padding: 0.5rem 3rem;
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 0.5s ease;
  &:hover {
    color: white;

    background-color: $bg-colour;
  }
  &:active {
    border: 2px solid $hover-colour;
    color: black;
  }
  &--dark {
    background-color: $bg-colour-dark;
    border: 2px solid #c5c2ab;
    color: #c5c2ab;
    &:hover {
      background-color: #c5c2ab;
      color: black;
    }
  }
}

.ripple-button > .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: $bg-colour-alt;
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.ripple-button > .content {
  position: relative;
  z-index: 2;
}
