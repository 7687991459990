@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.theme {
  position: absolute;
  top: 0.625rem;
  right: 0rem;
  @include tablet {
    right: 1.5rem;
    top: 1.25rem;
  }
  width: fit-content;
  height: fit-content;
}
