@use "./styles/partials/mixins" as *;
@use "./styles/partials/variables" as *;

.app {
  // transition: 0.5s;
  // display: flex;
  // flex-wrap: wrap;
  position: fixed;
  &--alt {
    background-color: $app-colour;
  }
  @include mobile-only {
    justify-content: center;
  }
  @include tablet {
    flex-wrap: nowrap;
  }
  &__container {
    // height: 100%;
    @include mobile-only {
      transform: scale(0.8);
      // height: 100vh;
      // display: flex;
      // align-items: center;
      // margin: auto;
    }
    @include tablet {
      height: 100%;
      @include fadeLeft;

      &--alt {
        background-color: white;
      }
      background-color: $bg-colour-dark;

      width: 35%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &__sections-container {
    width: 100%;
    height: 100%;
    @include tablet {
      @include fadeRight;
    }
  }
  &__page {
    @include tablet {
      width: 65%;
      height: 100%;
      float: right;
    }
  }
  &__section {
    height: 100%;
    &--alt {
      height: 100vh;
    }
    // @include tablet {
    // margin: calc(100% / 3) 0;
    // &--alt {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    // }
    // }
    // @include mobile-only {
    display: flex;
    justify-content: center;
    align-items: center;
    // transform: scale(0.8);
    // }
  }
}

.fp-overflow {
  overflow-y: visible !important;
  overflow-x: visible !important;
  // @include mobile-only {
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center !important;
  align-items: center !important;
  display: flex;
  height: 100%;
  width: 100%;
  // }
  // .app__section--about {
  //   width: 100;
  // }
}

.app__section.fp-section {
  display: flex !important;
  // overflow: visible;
  // height: 100vh !important;
  height: 100%;
  @include mobile-only {
    // height: 110vh;
  }
}

.app__section--work .fp-overflow {
  width: 100%;
  display: block;
  @include mobile-only {
    overflow-y: visible !important;
  }
  // @include tablet {

  // }
}
// .app__section--work.app__section--alt {
//   // @include mobile-only {
//   // justify-content: center;
//   // }
// }
.app--alt + #fp-nav.fp-right ul {
  filter: invert(1) !important ;
}
