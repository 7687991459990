@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.header {
  @include mobile-only {
    zoom: 90%;
  }
  @include tablet {
    zoom: 67%;
    @include fadeRight;
  }

  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;

  backdrop-filter: blur(10px);

  @include tablet {
    width: 65%;
    right: 0;
  }

  &__navbar {
    width: 100%;
  }
  &__ul {
    background-color: white;
    box-shadow: $shadow;
    border-radius: 25px;

    position: relative;
    display: flex;
    justify-content: space-between;
    width: 80%;
    // text-transform: uppercase;
    @include tablet {
      width: 40%;
      font-size: $size-text;
    }
    margin: 1rem auto;
    @include mobile-only {
      margin: 0.75rem auto;
      font-size: $size-text-mobile;
    }
    padding-left: 0;
  }
  &__ul--alt {
    background-color: $bg-colour-dark;
    // background-color: none;
  }
  &__link {
    list-style-type: none;
    @include mobile-only {
      padding: 0.5rem;
    }
    padding: 0.5rem 1rem;
    border-radius: 25px;
    font-weight: 400;
    border: 2px solid transparent;
    transition: all 0.25s;

    &:hover {
      @include tablet {
        @include pulse(154, 140, 152);
        background-color: $bg-colour;
        color: $palette-1;
      }
    }
  }

  &__link--alt {
    &:hover {
      @include tablet {
        filter: invert(1);
        // @include pulse(255, 255, 255);
      }
    }
  }
  &__link-tag {
    cursor: pointer;
    text-decoration: none;
    color: unset;
  }
}

.header__link.active {
  border: 2px solid;
  // @include mobile-only {
  @include pulse(154, 140, 152);
  background-color: $bg-colour;
  color: white;
  // }
}
.header__link--alt.active {
  // @include mobile-only {
  @include pulse(255, 255, 255);
  filter: invert(1);
  // }
}
