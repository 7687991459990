@use "./variables.scss" as *;

@mixin mobile-only {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin filterWhiteToColour {
  filter: brightness(0) saturate(100%) invert(29%) sepia(31%) saturate(483%)
    hue-rotate(195deg) brightness(91%) contrast(86%);
}
@mixin underline($bottom, $color) {
  position: relative;
  text-decoration: none;

  &::after {
    bottom: $bottom;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    background-color: transparent;
    transition: background-color 1s, width 1s, font-weight 1s ease-in-out;
    width: 0%;
  }
  &:hover::after {
    font-weight: 800;
    width: 100%;
    background-color: $color;
  }
}

@mixin pulse($r, $g, $b) {
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba($r, $g, $b, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0);
    }
  }
}

@keyframes fromRight {
  from {
    transform: translate(2.5%, 0);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@mixin fadeRight {
  animation: fromRight 0.25s ease-in both;
  animation-delay: 0.25s;
}

@keyframes fromLeft {
  from {
    transform: translate(-2.5%, 0);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@mixin fadeLeft {
  animation: fromLeft 0.25s ease-in both;
  animation-delay: 0.25s;
}
@mixin gradient($r, $g, $b) {
  background-image: linear-gradient(
    0deg,
    rgba($r, $g, $b, 0) 15%,
    rgba($r, $g, $b, 0.01) 17%,
    rgba($r, $g, $b, 0.02) 19%,
    rgba($r, $g, $b, 0.04) 21%,
    rgba($r, $g, $b, 0.06) 23%,
    rgba($r, $g, $b, 0.08) 25%,
    rgba($r, $g, $b, 0.1) 27%,
    rgba($r, $g, $b, 0.12) 39%,
    rgba($r, $g, $b, 0.14) 41%,
    rgba($r, $g, $b, 0.16) 43%,
    rgba($r, $g, $b, 0.18) 45%,
    rgba($r, $g, $b, 0.2) 47%,
    rgba($r, $g, $b, 0.22) 49%,
    rgba($r, $g, $b, 0.24) 51%,
    rgba($r, $g, $b, 0.26) 53%,
    rgba($r, $g, $b, 0.28) 55%,
    rgba($r, $g, $b, 0.3) 57%,
    rgba($r, $g, $b, 0.32) 59%,
    rgba($r, $g, $b, 0.34) 61%,
    rgba($r, $g, $b, 0.36) 63%,
    rgba($r, $g, $b, 0.38) 65%,
    rgba($r, $g, $b, 0.4) 67%,
    rgba($r, $g, $b, 0.42) 69%,
    rgba($r, $g, $b, 0.44) 71%,
    rgba($r, $g, $b, 0.46) 73%,
    rgba($r, $g, $b, 0.48) 75%,
    rgba($r, $g, $b, 0.5) 77%,
    rgba($r, $g, $b, 0.52) 79%,
    rgba($r, $g, $b, 0.54) 80%,
    rgba($r, $g, $b, 0.55) 81%,
    rgba($r, $g, $b, 0.56) 83%,
    rgba($r, $g, $b, 0.58) 85%,
    rgba($r, $g, $b, 0.6) 87%,
    rgba($r, $g, $b, 0.62) 89%,
    rgba($r, $g, $b, 0.7) 100%
  );
}
